
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($, _c, undefined) {
	'use strict';

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.

	var Sage = {
		
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages

				//hamburger
				(function() {
					$('.hamburger').on('click', function() {
						if ($(this).hasClass('is-active')) {
							$('.mobile-menu').slideDown(600);
						} else {
							$('.mobile-menu').slideUp(600);
						}
					});
				})();

                //fullpage
                $('#fullpage').fullpage({
                    css3: true,
                    scrollingSpeed: 700,
                    scrollOverflow: false,
                    fitToSection: true,
                    scrollBar: false,
                    easing: 'easeInOutCubic',
                    easingcss3: 'cubic-bezier(0.785, 0.135, 0.150, 0.860)',
                    keyboardScrolling: true,
                    sectionSelector: '.section',
                    lazyLoading: true,
                    menu: '#menu',
                    anchors:['section1', 'section2', 'section3', 'section4', 'section5', 'section6'],

                    //events
                    onLeave: function(index, nextIndex, direction){
                        if (index === 2){
                            $('#step1 .screenshot').removeClass('on');
                        }
                        if (index === 3){
                            $('#step2 .screenshot').removeClass('on');
                        }
                        if (index === 4){
                            $('#step3 .screenshot').removeClass('on');
                        }
                    },
                    afterLoad: function(anchorLink, index){
                        if (index === 2){
                            $('#step1 .screenshot').addClass('on');
                        }
                        if (index === 3){
                            $('#step2 .screenshot').addClass('on');
                        }
                        if (index === 4){
                            $('#step3 .screenshot').addClass('on');
                        }
                    },
                    afterRender: function(){},
                    afterResize: function(){},
                    afterResponsive: function(isResponsive){},
                    afterSlideLoad: function(anchorLink, index, slideAnchor, slideIndex){},
                    onSlideLeave: function(anchorLink, index, slideIndex, direction, nextSlideIndex){}
                });

                $(window).on('load', function(){
                    $('.dots div').addClass('on');
                    $('.page-title').addClass('on');
                    $('.mission').addClass('on');
                    $('form').addClass('on');
                    $('#hero .shape1').addClass('on');
                    $('#hero .shape2').addClass('on');
                    $('#hero .shape3').addClass('on');
                    $('#hero .shape4').addClass('on');
                    $('#hero .shape5').addClass('on');
                    $('#hero .shape6').addClass('on');
                    $('#hero .shape7').addClass('on');
                });

                $('.form-action a').on('focus', function(){
                    $(this).addClass('focus');
                });


                $('.form-action a').on('click', function(){
                    $(this).closest('form').submit();
                });


			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},


		// Home page
		'page_template_template_home': {
			init: function() {



            }
        },


	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = funcname === undefined ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.
