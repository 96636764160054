
(function(factory) {
	'use strict';

	if( document.querySelector('select') ) {
		factory( window.Clique );
	}
})(function(_c) {
	'use strict';

	// select placeholder plugin
	// (function() {
	// 	if (!_c.$.fn.selectric) {
	// 		_c.$.error('Selectric not initialized');
	// 	}

	// 	var hooks = _c.$.fn.selectric.hooks;

	// 	hooks.add('Init', 'placeholder', function(element, data) {
	// 		var $elm = _c.$(element);
	// 		var $wrapper = $elm.closest('.' + data.classes.wrapper);
	// 		var placeholder = $elm.attr('placeholder') || $elm.find('.gf_placeholder').text();

	// 		if( ! placeholder ) {
	// 			return;
	// 		}

	// 		$wrapper.find('.label')
	// 			.html( '<span class="label-placeholder">' + placeholder + '</span>' );
	// 		data.options = _c.$.extend({
	// 			placeholderOnOpen : true
	// 		}, data.options);
	// 	});

	// 	hooks.add('BeforeOpen', 'placeholder', function(element, data) {
	// 		if (data.options.placeholderOnOpen) {
	// 			var $elm = _c.$(element);
	// 			var placeholder = $elm.attr('placeholder') || $elm.find('.gf_placeholder').text();

	// 			if( ! placeholder ) {
	// 				return;
	// 			}
	// 			var $wrapper = $elm.closest('.' + data.classes.wrapper);
	// 			$wrapper.find('.label')
	// 				.data({
	// 					value : $wrapper.find('.label').html()
	// 				})
	// 				.html( '<span class="label-placeholder">' + placeholder + '</span>' );
	// 		}
	// 	});

	// 	hooks.add('BeforeClose', 'placeholder', function(element, data) {
	// 		if (data.options.placeholderOnOpen) {
	// 			var $elm = _c.$(element);
	// 			var placeholder = $elm.attr('placeholder') || $elm.find('.gf_placeholder').text();

	// 			if( ! placeholder ) {
	// 				return;
	// 			}
	// 			var $wrapper = $elm.closest('.' + data.classes.wrapper);

	// 			$wrapper.find('.label').html( $wrapper.find('.label').data('value') );
	// 		}
	// 	});
	// }());


	function initSelects() {
		if( _c.$.fn.selectric ) {
			_c.$('select').selectric({
				arrowButtonMarkup : '<button class="select-arrow button"></button>',
			});
		}
	}

	_c.$doc.one('ready.select', initSelects);
	_c.$doc.on('gform_post_render', initSelects);
});
